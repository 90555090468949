import * as React from 'react';
import '../../css/smallComponents.css';
import * as cx from 'classnames'

interface Props {
    id?: string
    modalClass?: string,
    dialogClass?: string
    contentClass?: string,
    style?: any
    dialogStyle?: any;
    children?: React.ReactNode
}

export default class Modal extends React.Component<Props, any> {

    render() {
        const { id, modalClass, dialogClass, contentClass, children, style, dialogStyle } = this.props;

        return (
            <div id={id} className={cx("modal show", modalClass)} style={style} role="dialog">
                <div className={cx("modal-dialog", dialogClass)} role="document" style={dialogStyle}>
                    <div className={cx("modal-content", contentClass)}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}

export const Modal2: React.FunctionComponent<Props> = props => {

    const { id, modalClass, dialogClass, contentClass, children, style, dialogStyle } = props;

    return (
        <div id={id} className={cx("modal show", modalClass)} style={style} role="dialog">
            <div className={cx("modal-dialog", dialogClass)} role="document" style={dialogStyle}>
                <div className={cx("modal-content", contentClass)}>
                    {children}
                </div>
            </div>
        </div>
    )
}
