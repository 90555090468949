import * as React from 'react';

export const Layout = ({ children }: React.PropsWithChildren<any>) => {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-sm-12"
            style={{ minHeight: 'calc(100vh - 670px)' }}
          >
            {children}
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="fl-page-footer-row row">
          <div className="col-sm-6 col-md-6 text-start clearfix">
            <div className="fl-page-footer-text fl-page-footer-text-1">
              {/*<span>� {new Date().getFullYear()} Core States Group</span>*/}
              {/*<span> | </span>*/}
              <img
                style={{ height: '70px' }}
                src="/static/img/CSG-new-logo-white-06.svg"
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-6 text-end clearfix">
            <div className="fl-page-footer-text fl-page-footer-text-2">
              {/*<div className="fl-icon-group footer-social-icons">*/}
              {/*  <span className="fl-icon">*/}
              {/*    <a href="http://linkedin.com/company/core-states-group" target="_blank" rel="noopener">*/}
              {/*      <i className="fab fa-linkedin-in" aria-hidden="true"></i>*/}
              {/*    </a>*/}
              {/*  </span>*/}
              {/*  <span className="fl-icon">*/}
              {/*    <a href="https://www.instagram.com/core_states_group/" target="_blank" rel="noopener">*/}
              {/*      <i className="fab fa-instagram" aria-hidden="true"></i>*/}
              {/*    </a>*/}
              {/*  </span>*/}
              {/*  <span className="fl-icon">*/}
              {/*    <a href="https://www.facebook.com/CoreStatesGroup" target="_blank" rel="noopener">*/}
              {/*      <i className="fab fa-facebook-f" aria-hidden="true"></i>*/}
              {/*    </a>*/}
              {/*  </span>*/}
              {/*  <span className="fl-icon"> <a href="https://twitter.com/corestatesinc" target="_blank" rel="noopener">*/}
              {/*    <i className="fab fa-twitter" aria-hidden="true"></i>*/}
              {/*  </a>*/}
              {/*  </span>*/}
              {/*</div>*/}
              {/*<br /> <br />*/}
              <a href="http://core-states.com/privacy-policy">
                CORE STATES GROUP&reg; Privacy Policy Request
              </a>
              <br /> CORE STATES GROUP&reg; Privacy Hotline Number:{' '}
              <a href="tel:18007788438">800.778.8438</a>
              <br /> <br /> GENERAL CONTRACTOR LICENSES <br /> California
              #948816 <br /> Florida #CBC034401 <br /> Florida #PCC045032
              (Pollutant Storage)
              <br /> Rhode Island #GC-35566 <br /> Virginia #2705151681
              <br /> Washington #CORESSC834MJ <br /> West Virginia #WV052962
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
