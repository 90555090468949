import * as React from 'react';
import { Trade } from '../../store/interface';

export interface TradeListDropDown {
  allTradeList: Trade[];
  selectedList: Trade[];
  updateList: (selected: Trade[]) => void;
  removeSelectedTradeList: (removeSelectedList: Trade[]) => void;
  selectedTrades: (selected: Trade[], plusMinus: number) => void;
  isInvalid: boolean;
}

export const TradeListSelection = ({ ...props }: TradeListDropDown) => {
  const addSelected = (e: React.FormEvent<HTMLButtonElement>) => {
    let a = e.currentTarget.parentElement!.parentElement!.getElementsByTagName(
      'select'
    )[0] as HTMLSelectElement;
    let b = Array.prototype.slice.call(a.options) as Array<HTMLOptionElement>;
    const c = b.filter((x) => x.selected);

    const d = c.map((x) => {
      const obj = {
        tradeId: parseInt(x.value),
        trade1: x.innerHTML,
      };
      return obj;
    });

    props.selectedTrades(d, 1);
    props.updateList(d);
  };

  const removeSelected = (e: React.FormEvent<HTMLButtonElement>) => {
    let a = e.currentTarget.parentElement!.parentElement!.getElementsByTagName(
      'select'
    )[1] as HTMLSelectElement;
    let b = Array.prototype.slice.call(a.options) as Array<HTMLOptionElement>;
    const c = b.filter((x) => x.selected);
    const d = c.map((x) => {
      const obj = {
        tradeId: parseInt(x.value),
        trade1: x.innerHTML,
      };
      return obj;
    });

    props.selectedTrades(d, 2);
    props.removeSelectedTradeList(d);
  };
  // sort alphabetically of a key in an array of objects
  const myCustomSort = (property: any) => {
    return (a: any, b: any) => {
      return a[property].localeCompare(b[property]);
    };
  };

  const allTradeList = React.useMemo(() => {
    return props.allTradeList
      .filter((x) => !props.selectedList.some((y) => y.trade1 == x.trade1))
      .map((x, i) =>
        x.trade1 != '' ? (
          <option key={i} className="tradeList" value={x.tradeId}>
            {x.trade1}
          </option>
        ) : (
          ''
        )
      );
  }, [props]);

  // use sorting method to alphabeticalize trade names displayed
  let selectedTradeList = props.selectedList
    .sort(myCustomSort('trade1'))
    .map((x, i) =>
      x.trade1 != '' ? (
        <option key={i} className="tradeList" value={x.tradeId}>
          {x.trade1}
        </option>
      ) : (
        ''
      )
    );

  return (
    <div className="">
      <strong className="">Trades* (select all that apply)</strong>
      <br />
      <div style={{ marginBottom: '20px' }} className="joined-list">
        <div className="select-list">
          <select className="form-select" multiple>
            {allTradeList}
          </select>
        </div>

        <div className="button-column">
          <button className="btn btn-sm btn-light-blue" onClick={addSelected}>
            {' '}
            <i className="bi bi-chevron-right"></i>
          </button>
          <br />
          <button className="btn btn-sm btn-orange" onClick={removeSelected}>
            {' '}
            <i className="bi bi-chevron-left"></i>
          </button>
        </div>

        <div className="select-list">
          <select
            className={`form-select ${!props.isInvalid ? '' : 'is-invalid'} `}
            multiple
          >
            {selectedTradeList}
          </select>
          <div className="invalid-feedback">
            Please select at least one trade
          </div>
        </div>
      </div>
    </div>
  );
};
