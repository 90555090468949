
export const formatInputCurrency = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = e.currentTarget.value.replace(/[^\d]/g, '');

  value = toMoney(value, 0);

  e.currentTarget.value = value;
}

export const formatDate = (date: string) => {
  if (!date || !date.length) return "";
  const myDate = new Date(date);
  return `${myDate.getMonth() + 1}/${myDate.getDate()}/${myDate.getFullYear()}`
}

export const formatDateTime = (date: string) => {
  if (!date || !date.length) return "";
  const myDate = new Date(date);
  const hour = myDate.getHours() === 12 ? 12 : myDate.getHours() % 12, amPm = myDate.getHours() > 12 ? 'PM' : 'AM'
  return `${myDate.getMonth() + 1}/${myDate.getDate()}/${myDate.getFullYear()} ${hour}:${lPad(myDate.getMinutes().toString(), 2, '0')} ${amPm}`
}

export const formatDateInputFriendly = (date: string) => {
  const str = date && date.length > 0 && Date.parse(date) ? new Date(date).toISOString().substring(0, 10) : ""
  return str;
}

export const lPad = (str: string, num: number, char: string) => {
  while (str.length < num) {
    str = char + str;
  }

  return str;
}

export const check_money_format = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const field = e.currentTarget
  const amount = field.value.replace(/\$/g, '').replace(/,/g, '');

  field.value = toMoney(parseFloat(amount));
}

export const toMoney = (valNo: number | string, pts?: number) => {
  valNo = typeof (valNo) === 'number' ? valNo : parseInt(valNo);
  let n = (Math.round(valNo * 100) / 100).toString();
  const decimals = pts || 0, d = ".", t = ",", s = valNo < 0 ? "-" : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(decimals))),
    m = parseInt(i);
  let j: any;
  j = (j = i.length) > 3 ? j % 3 : 0;
  return s + "$" + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (decimals ? d + Math.abs(valNo - m).toFixed(decimals).slice(2) : "");
}