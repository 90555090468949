import * as React from 'react';
import { Modal2 } from './SubComponent/Modal';

export const ConstructionInsuranceMinimumsNotice = () => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <a href="javascript:void(0);" onClick={() => setShowModal(true)}>
        Click here to see our minimum insurance requirements
      </a>
      {showModal && (
        <Modal2
          dialogClass="white insurance-notice"
          dialogStyle={{ width: '50%' }}
        >
          <div className="modal-body">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3>Minimum Insurance Requirements</h3>
              </div>
              <div className="panel-body">
                <ul>
                  <li>
                    Commercial General Liability with a minimum of $1,000,000
                  </li>
                  <li>
                    Commercial Automobile Liability with a minimum of $1,000,000
                  </li>
                  <li>
                    Professional Liability with a minimum of $2,000,000 required
                    for design work only
                  </li>
                  <li>
                    Workers Compensation required by CoreStates insurance policy
                    minimum of $500,000, regardless of state law owner
                    exemptions.
                  </li>
                </ul>
                <p className="text-danger">
                  The Certificate of Insurance must include the above coverage
                  and must name{' '}
                  <strong>Core States Construction Services, Inc.</strong> as an
                  additional insured. The following information must be listed
                  on the Certificate of Liability Insurance:{' '}
                  <strong>
                    {' '}
                    "Core States Construction Services, Inc. is named as
                    additional insured on a primary and non-contributory basis
                    under the above mentioned polices except the workers
                    compensation. This includes ongoing & completed operations.
                    A waiver of subrogation in their favor is included in all of
                    the above-mentioned policies"
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-sm btn-light"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </Modal2>
      )}
    </>
  );
};

export const CSIInsuranceMinimumsNotice = () => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <a href="javascript:void(0);" onClick={() => setShowModal(true)}>
        Click here to see our minimum insurance requirements
      </a>
      {showModal && (
        <Modal2
          dialogClass="white insurance-notice"
          dialogStyle={{ width: '50%' }}
        >
          <div className="modal-body">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3>Minimum Insurance Requirements</h3>
              </div>
              <div className="panel-body">
                <ul>
                  <li>
                    Commercial General Liability with a minimum of $1,000,000
                  </li>
                  <li>
                    Commercial Automobile Liability with a minimum of $1,000,000
                  </li>
                  <li>
                    Professional Liability with a minimum of $2,000,000 required
                    for design work only
                  </li>
                  <li>
                    Workers Compensation required by CoreStates insurance policy
                    minimum of $500,000, regardless of state law owner
                    exemptions.
                  </li>
                </ul>
                <p className="text-danger">
                  The Certificate of Insurance must include the above coverage
                  and must name <strong>Core States, Inc.</strong> as an
                  additional insured. The following information must be listed
                  on the Certificate of Liability Insurance:{' '}
                  <strong>
                    {' '}
                    "Core States, Inc. is named as additional insured on a
                    primary and non-contributory basis under the above mentioned
                    polices except the workers compensation. This includes
                    ongoing & completed operations. A waiver of subrogation in
                    their favor is included in all of the above-mentioned
                    policies"
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-sm btn-light"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </Modal2>
      )}
    </>
  );
};
