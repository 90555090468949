import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { F40Form, SubFormVM } from '../../store/interface';
import { states } from '../constants';
import Modal, { Modal2 } from '../SubComponent/Modal';
import ContentLoader from 'react-content-loader';
import { F40FormComponent } from '../f40form/F40Form';
import { FinancialsFormComponent } from '../financialsform/FinancialsFormComponent';
import { formatDate } from '../../helpers';
import { Home } from '../Home';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';
import { useDispatch } from 'react-redux';
import * as SubFormState from '../../store/SubForm';

export interface PhaseTwoSummaryVm {
  formId: number;
  vendorId: number;
  formType: 'f40' | 'financials';
  formName: string;
  status: string;
  lastModified: string | null;
}

export const P2Page = (props: RouteComponentProps<{ token: string }>) => {
  //export const F40Page = () => {

  const [forms, setForms] = React.useState([] as PhaseTwoSummaryVm[]);
  const [infoEdit, setInfoEdit] = React.useState<boolean>();
  const [selectedForm, setSelectedForm] = React.useState(
    null as PhaseTwoSummaryVm | null
  );
  const [loading, setLoading] = React.useState(true);
  const [popupMessage, setPopupMessage] = React.useState(null as string | null);

  const [errorMessage, setErrorMessage] = React.useState(null as string | null);

  const subFormStore = useSelector((s: ApplicationState) => s.subForm);
  const subForm = subFormStore.subForm;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (forms.length > 0) {
      dispatch(SubFormState.actionCreators.getVendorForm(forms[0].vendorId));
    }
  }, [forms]);

  const _getForms = (token: string) => {
    fetch(`api/phasetwo/getforms?token=${token}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        setLoading(false);
        if (resOk) {
          setForms(data);
        } else {
          setErrorMessage(data.message);
        }
      });
  };

  React.useEffect(() => {
    _getForms(props.match.params.token);
  }, [props.match.params.token]);

  const errorComponent = errorMessage ? (
    <div className="f40-error-message">
      <h4>{errorMessage}</h4>
    </div>
  ) : undefined;
  const popupMessageComponent = popupMessage ? (
    <Modal2>
      <div className="modal-body">{popupMessage}</div>
      <div className="modal-footer">
        <button
          className="btn btn-sm btn-light"
          style={{ color: 'black' }}
          onClick={() => setPopupMessage(null)}
        >
          Close
        </button>
      </div>
    </Modal2>
  ) : (
    []
  );

  const backComponent = infoEdit ? (
    <button
      onClick={() => {
        setInfoEdit(false);
      }}
      className="p2-back-button"
    >
      <i className="bi bi-chevron-left"></i> Back
    </button>
  ) : selectedForm === null ? (
    []
  ) : (
    <button
      onClick={() => {
        setSelectedForm(null);
      }}
      className="p2-back-button"
    >
      <i className="bi bi-chevron-left"></i> Back
    </button>
  );

  const mainComponent = infoEdit ? (
    <div>
      <h2 style={{ textAlign: 'center' }}>General Information</h2>
      <Home subForm={subForm} />
    </div>
  ) : selectedForm === null ? (
    <>
      <h2 style={{ textAlign: 'center' }}>Partner Registration Forms</h2>
      <F40FormRow>
        {forms && forms.length ? (
          <>
            {subForm && (
              <div
                key={'gi'}
                className="p2-form-card"
                onClick={() => setInfoEdit(true)}
              >
                <i className="bi bi-list-ul "></i>
                <div className="card-title">General Information</div>
                <div>
                  Last modified:{' '}
                  {subForm.lastModified
                    ? formatDate(subForm.lastModified)
                    : 'N/A'}
                </div>
              </div>
            )}
            {forms.map((x) => {
              const iconGlyph =
                x.formType === 'financials'
                  ? 'bi bi-currency-dollar'
                  : x.formType === 'f40'
                  ? 'bi bi-clipboard'
                  : '';
              return (
                <div
                  key={x.formId}
                  className="p2-form-card"
                  onClick={() => setSelectedForm(x)}
                >
                  <i className={iconGlyph}></i>
                  <div className="card-title">{x.formName}</div>
                  <div>{x.status}</div>
                  <div>
                    Last modified:{' '}
                    {x.lastModified ? formatDate(x.lastModified) : 'N/A'}
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <h4 style={{ textAlign: 'center' }}>No active forms found.</h4>
        )}
      </F40FormRow>
    </>
  ) : selectedForm.status === 'Submitted' ? (
    <h4 style={{ textAlign: 'center' }}>
      This form has already submitted. If you need to submit any amendments,
      please contact us.
    </h4>
  ) : selectedForm.formType === 'f40' ? (
    <F40FormComponent token={props.match.params.token} />
  ) : selectedForm.formType === 'financials' ? (
    <FinancialsFormComponent token={props.match.params.token} />
  ) : (
    []
  );

  // @ts-ignore
  if (loading) {
    return (
      <div className="main-item">
        <div
          className="animated-background"
          style={{ height: '200px', marginTop: '10px' }}
        >
          <div className="background-masker"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="f40-page" style={{ minHeight: '250px' }}>
      {popupMessageComponent}
      {backComponent}
      {errorComponent ? errorComponent : mainComponent}
    </div>
  );
};

export const F40FormSection = ({
  children,
  title,
}: React.PropsWithChildren<any>) => (
  <div className="f40-form-section">
    <div className="section-header">
      <h3>{title}</h3>
    </div>
    <div className="section-body">{children}</div>
  </div>
);

export const F40FormRow = ({
  children,
  title,
}: React.PropsWithChildren<any>) => (
  <div className="f40-form-row">{children}</div>
);

export const F40FormGroup = ({
  children,
  label,
  width,
  noFlex,
  labelWidth,
  style,
}: React.PropsWithChildren<{
  label?: string | undefined;
  width: string;
  noFlex?: boolean;
  labelWidth?: string;
  style?: any;
}>) => (
  <div
    className="f40-form-group form-group input-group"
    style={
      style
        ? { ...style, width: width }
        : { display: noFlex ? 'inline-block' : 'inline-flex', width: width }
    }
  >
    {label && (
      <b className="form-label" style={labelWidth ? { width: labelWidth } : {}}>
        {label}
      </b>
    )}
    {children}
  </div>
);
