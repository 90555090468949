import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FinancialsForm } from '../../store/interface';
import Modal, { Modal2 } from '../SubComponent/Modal';
import { formatInputCurrency } from '../../helpers';
import {
  F40FormGroup,
  F40FormRow,
  F40FormSection,
  F40FormValidationGroup,
} from '../f40form/F40Form';
import {
  CSIInsuranceMinimumsNotice,
  ConstructionInsuranceMinimumsNotice,
} from '../InsuranceMinimumsNotice';
import { SliderCheckbox } from '../SubComponent/SliderCheckbox';

export const FinancialsFormComponent = (props: { token: string }) => {
  //export const F40Page = () => {

  const [form, setForm] = React.useState(
    undefined as FinancialsForm | undefined
  );
  const [loading, setLoading] = React.useState(true);
  const [validated, setValidate] = React.useState(false);
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [removeAttachmentId, setRemoveAttachmentId] = React.useState(
    null as number | null
  );
  const [popupMessage, setPopupMessage] = React.useState(null as string | null);

  const [errorMessage, setErrorMessage] = React.useState(null as string | null);

  React.useEffect(() => {
    _getForm(props.token);
  }, [props.token]);

  React.useEffect(() => {
    if (form) {
      setValidate(_checkFormValidation(form));
    }
  }, [form]);

  const _checkFormValidation = (form: FinancialsForm) => {
    if (form.dbNumber === null || form.dbNumber === '') return false;

    if (form.recentFYMonth === null || form.recentFYMonth === '') return false;
    if (form.recentFYYear === null || form.recentFYYear === '') return false;
    if (!form.singleJobBondingLimit || !form.singleJobBondingLimit.length)
      return false;
    if (!form.aggregateBondingLimit || !form.aggregateBondingLimit.length)
      return false;
    if (!form.claimAgainstBond || !form.claimAgainstBond.length) return false;

    // Do we need to check these? What if they're validly 0
    if (form.fyRevenues === null || form.fyRevenues === '') return false;
    if (form.fyCurrentAssets === null || form.fyCurrentAssets === '')
      return false;
    if (form.fyTotalAssets === null || form.fyTotalAssets === '') return false;
    if (form.fyCurrentLiabilities === null || form.fyCurrentLiabilities === '')
      return false;
    if (form.fyTotalLiabilities === null || form.fyTotalLiabilities === '')
      return false;
    if (form.fyLongTermDebt === null || form.fyLongTermDebt === '')
      return false;
    if (form.fyNetIncome === null || form.fyNetIncome === '') return false;
    if (form.fyCashFlow === null || form.fyCashFlow === '') return false;
    if (form.digitalSignature === null || form.digitalSignature === '')
      return false;
    if (
      form.digitalSignatureTitle === null ||
      form.digitalSignatureTitle === ''
    )
      return false;
    if (form.subjectToLiens) {
      if (!form.liensDescription || !form.liensDescription.length) return false;
    }
    if (form.involvedInLitigation) {
      if (!form.litigationExplanation || !form.litigationExplanation.length)
        return false;
    }
    if (form.bankruptcyLast5Years) {
      if (!form.bankruptcyDescription || !form.bankruptcyDescription.length)
        return false;
    }

    // COI is required
    if (form.attachments === null || form.attachments.length === 0) {
      return false;
    }

    return true;
  };

  const _getForm = (token: string) => {
    fetch(`api/financials/form?token=${token}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        setLoading(false);
        if (resOk) {
          setForm(data);
        } else {
          setErrorMessage(data.message);
        }
      });
  };

  const _onUpdate = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.FocusEvent<HTMLInputElement>
  ) => {
    let value: string | boolean | number = '';
    if (e.currentTarget.type === 'checkbox') {
      value = (e.currentTarget as HTMLInputElement).checked;
    } else if (e.currentTarget.type === 'radio') {
      const dataValue = e.currentTarget.getAttribute('data-value');
      if (dataValue) {
        value = dataValue;
      } else {
        const boolValue = e.currentTarget.getAttribute('data-bool-value');
        value = boolValue === 'false' ? false : true;
      }
    } else if (e.currentTarget.type === 'number') {
      value = parseFloat(e.currentTarget.value);
    } else {
      value = e.currentTarget.value;
    }

    const split = e.currentTarget.name.split('.');
    const newForm = { ...form } as FinancialsForm;
    if (split.length === 2) {
      // @ts-ignore
      newForm[split[0]][split[1]] = value;
    } else newForm[split[0]] = value;

    setForm({ ...newForm });
    setUnsavedChanges(true);
  };

  const _removeAttachment = () => {
    fetch(
      `api/financials/removeattachment?attachmentId=${removeAttachmentId}`,
      {
        method: 'DELETE',
      }
    )
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk && form) {
          setForm({
            ...form,
            attachments: form.attachments.filter(
              (x) => x.id !== removeAttachmentId
            ),
          });
          setTimeout(() => setRemoveAttachmentId(null), 300);
        }
      });
  };

  const _save = () => {
    const formData = new FormData();

    formData.append('json', JSON.stringify(form));
    const insurances = document.getElementById(
      'insurance_files'
    ) as HTMLInputElement;

    if (insurances && insurances.files) {
      Array.from(insurances.files).forEach((file, i) => {
        formData.append(`insuranceFiles[${i}]`, file, file.name);
      });
    }

    fetch(`api/financials/save`, {
      method: 'PUT',
      body: formData,
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setForm(data as FinancialsForm);
          setUnsavedChanges(false);
          setPopupMessage(
            'Your changes have been successfully saved! You can now submit your form, or come back later to continue filling out the form.'
          );
        } else {
          setErrorMessage(data.message);
        }
      });
  };

  const _submit = () => {
    // validate all the required fields
    if (form && validated) {
      fetch(`api/financials/submit?vendorId=${form.vendorDetailId}`, {
        method: 'PUT',
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            setForm(data as FinancialsForm);
            setUnsavedChanges(false);
            setPopupMessage(
              'Your form has been successfully submitted! You should receive an automated confirmation email shortly.'
            );
          } else {
            setErrorMessage(data.message);
          }
        });
    }
  };

  const insuranceDescriptionText = (insuranceScopes: string) => {
    const split = insuranceScopes.split(',');
    if (split.length === 2) {
      return (
        <span>
          Please ensure that both <b>Core States, Inc.</b> and{' '}
          <b>Core States Construction Services, Inc.</b> are named on your
          insurance. <ConstructionInsuranceMinimumsNotice />
        </span>
      );
    } else if (split[0] === 'construction') {
      return (
        <span>
          Please ensure that <b>Core States Construction Services, Inc.</b> is
          named on your insurance. <ConstructionInsuranceMinimumsNotice />
        </span>
      );
    } else if (split[0] === 'archeng') {
      return (
        <span>
          Please ensure that <b>Core States, Inc.</b> is named on your
          insurance. <CSIInsuranceMinimumsNotice />
        </span>
      );
    }
  };

  const errorComponent = errorMessage ? (
    <div className="f40-error-message">
      <h4>{errorMessage}</h4>
    </div>
  ) : (
    []
  );
  const popupMessageComponent = popupMessage ? (
    <Modal2>
      <div className="modal-body">{popupMessage}</div>
      <div className="modal-footer">
        <button
          className="btn btn-sm btn-light"
          style={{ color: 'black' }}
          onClick={() => setPopupMessage(null)}
        >
          Close
        </button>
      </div>
    </Modal2>
  ) : (
    []
  );
  const removeAttachmentComponent = removeAttachmentId ? (
    <Modal2 dialogClass="white">
      <div className="modal-body" style={{ textAlign: 'center' }}>
        Are you sure you want to delete this attachment?
      </div>
      <div className="modal-footer">
        <button className="btn btn-sm btn-danger" onClick={_removeAttachment}>
          Delete
        </button>
        <button
          className="btn btn-sm btn-secondary"
          onClick={() => setRemoveAttachmentId(null)}
        >
          Cancel
        </button>
      </div>
    </Modal2>
  ) : (
    []
  );

  const formComponent = form ? (
    <div className="f40-form">
      <h2 style={{ textAlign: 'center' }}>Qualified Financial Information</h2>
      <p>
        <u>
          <b>Please Note: </b> <u>All fields below are required.</u> You can
          save your changes as you work on this form to return later.
        </u>
      </p>
      <F40FormSection title="Section 1 - General">
        <F40FormRow>
          <F40FormGroup label="Company Name" width="100%">
            <input className="form-control" disabled value={form.companyName} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Address" width="33%">
            <input className="form-control" disabled value={form.address} />
          </F40FormGroup>
          <F40FormGroup label="City" width="33%">
            <input className="form-control" disabled value={`${form.city}`} />
          </F40FormGroup>
          <F40FormGroup label="State/Province" width="33%">
            <input
              className="form-control"
              disabled
              value={`${form.stateProvince}`}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Zip/Postal Code" width="33%">
            <input className="form-control" disabled value={form.zip} />
          </F40FormGroup>
          <F40FormGroup label="Country" width="33%">
            <input className="form-control" disabled value={form.country} />
          </F40FormGroup>
          <F40FormGroup label="Phone" width="33%">
            <input className="form-control" disabled value={form.phone} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="D&B Number*" width="33%">
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.dbNumber === null || form.dbNumber === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="dbNumber"
                value={form.dbNumber || ''}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid D&B Number.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <p>
            <b>
              List your three largest contract values for the last twelve
              months:
            </b>
          </p>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Contract 1" width="33%">
            <input
              className="form-control"
              name="contractAmount1"
              defaultValue={form.contractAmount1}
              onChange={formatInputCurrency}
              onBlur={_onUpdate}
            />
          </F40FormGroup>
          <F40FormGroup label="Contract 2" width="33%">
            <input
              className="form-control"
              name="contractAmount2"
              defaultValue={form.contractAmount2}
              onChange={formatInputCurrency}
              onBlur={_onUpdate}
            />
          </F40FormGroup>
          <F40FormGroup label="Contract 3" width="33%">
            <input
              className="form-control"
              name="contractAmount3"
              defaultValue={form.contractAmount3}
              onChange={formatInputCurrency}
              onBlur={_onUpdate}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="25%"
            label="Is your company subject to any liens?*"
            labelWidth="300px"
          >
            <input
              type="radio"
              name="subjectToLiens"
              checked={form.subjectToLiens}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="subjectToLiens"
              checked={!form.subjectToLiens}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          {form.subjectToLiens && (
            <F40FormGroup width="100%" label="Please explain the liens*" noFlex>
              <F40FormValidationGroup>
                <textarea
                  className={`form-control ${
                    !validated &&
                    //!unsavedChanges &&
                    (!form.liensDescription || !form.liensDescription.length)
                      ? 'is-invalid'
                      : ''
                  } `}
                  rows={3}
                  name="liensDescription"
                  value={form.liensDescription}
                  onChange={_onUpdate}
                />
                <div className="invalid-feedback">
                  Please provide valid lien explanations.
                </div>
              </F40FormValidationGroup>
            </F40FormGroup>
          )}
        </F40FormRow>
        <F40FormRow>
          <p>
            <b>
              The following figures refer to your financials at the end of your
              most recent fiscal year. Please indicate the month and year in
              which your fiscal year ended:
            </b>
          </p>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Month*" width="20%" labelWidth="180px">
            <F40FormValidationGroup>
              <select
                className={`form-select ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.recentFYMonth === null || form.recentFYMonth === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="recentFYMonth"
                value={form.recentFYMonth || ''}
                onChange={_onUpdate}
                style={{ width: 'calc(100% - 180px)' }}
              >
                <option value=""></option>
                <option value="Jan">Jan</option>
                <option value="Feb">Feb</option>
                <option value="Mar">Mar</option>
                <option value="Apr">Apr</option>
                <option value="May">May</option>
                <option value="Jun">Jun</option>
                <option value="Jul">Jul</option>
                <option value="Aug">Aug</option>
                <option value="Sep">Sep</option>
                <option value="Oct">Oct</option>
                <option value="Nov">Nov</option>
                <option value="Dec">Dec</option>
              </select>
              <div className="invalid-feedback">
                Please provide a valid Month.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
          <F40FormGroup label="Year*" width="21.2%" labelWidth="50px">
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.recentFYYear === null || form.recentFYYear === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="recentFYYear"
                value={form.recentFYYear}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Year.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Revenue*" width={'33%'}>
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.fyRevenues === null || form.fyRevenues === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="fyRevenues"
                defaultValue={form.fyRevenues}
                onChange={formatInputCurrency}
                onBlur={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide valid Revenue.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Current Assets*" width={'33%'}>
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.fyCurrentAssets === null || form.fyCurrentAssets === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="fyCurrentAssets"
                defaultValue={form.fyCurrentAssets}
                onChange={formatInputCurrency}
                onBlur={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide valid Currency Assets.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Total Assets*" width={'33%'}>
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.fyTotalAssets === null || form.fyTotalAssets === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="fyTotalAssets"
                defaultValue={form.fyTotalAssets}
                onChange={formatInputCurrency}
                onBlur={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide valid Total Assets.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Current Liabilities*" width={'33%'}>
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.fyCurrentLiabilities === null ||
                    form.fyCurrentLiabilities === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="fyCurrentLiabilities"
                defaultValue={form.fyCurrentLiabilities}
                onChange={formatInputCurrency}
                onBlur={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide valid Liabilities.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Total Liabilities*" width={'33%'}>
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.fyTotalLiabilities === null ||
                    form.fyTotalLiabilities === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="fyTotalLiabilities"
                defaultValue={form.fyTotalLiabilities}
                onChange={formatInputCurrency}
                onBlur={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide valid Liabilities.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Long Term Debt*" width={'33%'}>
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.fyLongTermDebt === null || form.fyLongTermDebt === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="fyLongTermDebt"
                defaultValue={form.fyLongTermDebt}
                onChange={formatInputCurrency}
                onBlur={_onUpdate}
              />
              <div className="invalid-feedback">Please provide valid Debt.</div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Retained Earnings" width={'33%'}>
            <input
              className="form-control"
              name="fyRetainedEarnings"
              defaultValue={form.fyRetainedEarnings}
              onChange={formatInputCurrency}
              onBlur={_onUpdate}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Shareholder Equity" width={'33%'}>
            <input
              className="form-control"
              name="fyEquity"
              defaultValue={form.fyEquity}
              onChange={formatInputCurrency}
              onBlur={_onUpdate}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Net Income*" width={'33%'}>
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.fyNetIncome === null || form.fyNetIncome === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="fyNetIncome"
                defaultValue={form.fyNetIncome}
                onChange={formatInputCurrency}
                onBlur={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide valid Income.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Cash Flow From Operations*" width={'33%'}>
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.fyCashFlow === null || form.fyCashFlow === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="fyCashFlow"
                defaultValue={form.fyCashFlow}
                onChange={formatInputCurrency}
                onBlur={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide valid Cash Flow information.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="100%"
            labelWidth="560px"
            label="Has your firm or its officers declared a business bankruptcy in the last five years?"
          >
            <input
              type="radio"
              name="bankruptcyLast5Years"
              checked={form.bankruptcyLast5Years}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="bankruptcyLast5Years"
              checked={!form.bankruptcyLast5Years}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        {form.bankruptcyLast5Years && (
          <F40FormRow>
            <F40FormGroup
              width="100%"
              labelWidth="310px"
              label="Please explain the bankruptcy declaration*"
              noFlex
            >
              <F40FormValidationGroup>
                <textarea
                  className={`form-control ${
                    !validated &&
                    //!unsavedChanges &&
                    (!form.bankruptcyDescription ||
                      !form.bankruptcyDescription.length)
                      ? 'is-invalid'
                      : ''
                  } `}
                  rows={3}
                  value={form.bankruptcyDescription}
                  name="bankruptcyDescription"
                  onChange={_onUpdate}
                ></textarea>
                <div className="invalid-feedback">
                  Please provide a valid explanation.
                </div>
              </F40FormValidationGroup>
            </F40FormGroup>
          </F40FormRow>
        )}
        <F40FormRow>
          <F40FormGroup
            width="100%"
            labelWidth="315px"
            label="Are you currently involved in any litigation?"
          >
            <input
              type="radio"
              name="involvedInLitigation"
              checked={form.involvedInLitigation}
              onChange={_onUpdate}
            />
            Yes &nbsp; &nbsp;
            <input
              type="radio"
              name="involvedInLitigation"
              checked={!form.involvedInLitigation}
              onChange={_onUpdate}
              data-bool-value={false}
            />
            No
          </F40FormGroup>
        </F40FormRow>
        {form.involvedInLitigation && (
          <F40FormRow>
            <F40FormGroup
              width="100%"
              labelWidth="250px"
              label="Please explain the litigation*"
              noFlex
            >
              <F40FormValidationGroup>
                <textarea
                  className={`form-control ${
                    !validated &&
                    //!unsavedChanges &&
                    (!form.litigationExplanation ||
                      !form.litigationExplanation.length)
                      ? 'is-invalid'
                      : ''
                  } `}
                  rows={3}
                  value={form.litigationExplanation}
                  name="litigationExplanation"
                  onChange={_onUpdate}
                ></textarea>
                <div className="invalid-feedback">
                  Please provide a valid explanation.
                </div>
              </F40FormValidationGroup>
            </F40FormGroup>
          </F40FormRow>
        )}
        <F40FormRow>
          <F40FormGroup
            width="100%"
            labelWidth="350px"
            label="What is your single-job bonding limit*"
          >
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (!form.singleJobBondingLimit ||
                  !form.singleJobBondingLimit.length)
                    ? 'is-invalid'
                    : ''
                } `}
                defaultValue={form.singleJobBondingLimit}
                name="singleJobBondingLimit"
                onChange={formatInputCurrency}
                onBlur={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide valid single-job bonding limit.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="100%"
            labelWidth="350px"
            label="What is your aggregate bonding limit*"
          >
            <F40FormValidationGroup>
              <input
                className={`form-control ${!validated &&
                    //!unsavedChanges &&
                    (!form.aggregateBondingLimit ||
                      !form.aggregateBondingLimit.length)
                    ? 'is-invalid'
                    : ''
                  } `}
                defaultValue={form.aggregateBondingLimit}
                name="aggregateBondingLimit"
                onChange={formatInputCurrency}
                onBlur={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide valid aggregate bonding limit.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="100%"
            labelWidth="350px"
            label="Have you ever had a claim against your bond"
          >
            <input
              className="form-control"
              value={form.claimAgainstBond}
              name="claimAgainstBond"
              onChange={_onUpdate}
            />
          </F40FormGroup>
        </F40FormRow>
        <p
          style={{ marginLeft: '10px', marginBottom: '5px', fontSize: '12px' }}
        >
          {insuranceDescriptionText(form.insuranceScopes)}
        </p>
        <F40FormRow>
          <F40FormGroup width="100%">
            <b style={{ width: '350px' }}>Please upload your COI*</b>
            <input
              type="file"
              id="insurance_files"
              name="insurance_files"
              accept=".pdf"
              onChange={() => setUnsavedChanges(true)}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          {form.attachments.map((x) => {
            return (
              <p key={x.id}>
                {x.fileName} &nbsp;
                <i
                  className="bi bi-trash"
                  onClick={() => setRemoveAttachmentId(x.id)}
                  style={{ cursor: 'pointer', fontSize: '16px' }}
                  title="Remove attachment"
                ></i>
              </p>
            );
          })}
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="50%" label="Signature*">
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.digitalSignature === null ||
                    form.digitalSignature === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="digitalSignature"
                value={form.digitalSignature}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Signature.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
          <F40FormGroup width="50%" label="Title*">
            <F40FormValidationGroup>
              <input
                className={`form-control ${
                  !validated &&
                  //!unsavedChanges &&
                  (form.digitalSignatureTitle === null ||
                    form.digitalSignatureTitle === '')
                    ? 'is-invalid'
                    : ''
                } `}
                name="digitalSignatureTitle"
                value={form.digitalSignatureTitle}
                onChange={_onUpdate}
              />
              <div className="invalid-feedback">
                Please provide a valid Title.
              </div>
            </F40FormValidationGroup>
          </F40FormGroup>
        </F40FormRow>
      </F40FormSection>
      {!form.isSubmitted && (
        <div>
          <F40FormGroup width="100%" noFlex>
            {unsavedChanges && (
              <button className="btn btn-lg btn-light-blue" onClick={_save}>
                Save
              </button>
            )}
            {!unsavedChanges &&
              !validated && (
              <>
                <p style={{ marginBottom: '5px' }}>
                  <b style={{ color: 'red' }}>
                    Please fill out all required fields (marked with a *) above
                  </b>
                </p>
              </>
            )}
            {!unsavedChanges && (
              <button
                className="btn btn-lg btn-dark-blue"
                onClick={_submit}
                disabled={!validated}
              >
                Submit
              </button>
            )}
          </F40FormGroup>
        </div>
      )}
    </div>
  ) : (
    []
  );

  // @ts-ignore
  if (loading) {
    return (
      <div className="main-item">
        <div
          className="animated-background"
          style={{ height: '200px', marginTop: '10px' }}
        >
          <div className="background-masker"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="f40-page">
      {errorComponent}
      {popupMessageComponent}
      {removeAttachmentComponent}
      {formComponent}
    </div>
  );
};
