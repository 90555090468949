import * as React from 'react';
import { serviceAreas } from '../constants';

export interface ServiceAreasProps {
  selectedStatesList: (selected: string[]) => void;
  selectedStates: string[];
  isInvalid: boolean;
}

class State {
  readonly allStates: string[] = [];
  readonly selectedStates: string[] = [];
}

export default class ServiceAreas extends React.Component<
  ServiceAreasProps,
  State
> {
  constructor(props: any) {
    super(props);

    this.state = {
      allStates: [],
      selectedStates: this.props.selectedStates,
    };
  }

  componentDidMount() {
    fetch(`api/subform/serviceareas`)
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        this.setState({ allStates: data })
      })
  }

  addSelected = (e: React.FormEvent<HTMLButtonElement>) => {
    let a = e.currentTarget.parentElement!.parentElement!.getElementsByTagName(
      'select'
    )[0] as HTMLSelectElement;
    let b = Array.prototype.slice.call(a.options) as Array<HTMLOptionElement>;
    const c = b.filter((x) => x.selected);

    let arr: string[] = [];

    c.map((x) => {
      return arr.push(x.value);
    });

    const all = this.state.allStates;
    // removing the selected from the allStatesList
    for (let i = 0; i < all.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        if (all[i] == arr[j]) {
          all.splice(i, 1);
        }
      }
    }

    this.setState({
      selectedStates: this.state.selectedStates.concat(arr),
    });

    //sending to redux to update
    this.props.selectedStatesList(this.state.selectedStates.concat(arr));
  };

  removeSelected = (e: React.FormEvent<HTMLButtonElement>) => {
    let a = e.currentTarget.parentElement!.parentElement!.getElementsByTagName(
      'select'
    )[1] as HTMLSelectElement;
    let b = Array.prototype.slice.call(a.options) as Array<HTMLOptionElement>;
    const c = b.filter((x) => x.selected);

    let selectedArr: string[] = [];

    c.map((x) => {
      return selectedArr.push(x.value);
    });

    //remove the selected states
    const selectedState = this.state.selectedStates;
    for (let i = 0; i < selectedState.length; i++) {
      for (let j = 0; j < selectedArr.length; j++) {
        if (selectedArr[j] == selectedState[i]) {
          selectedState.splice(i, 1);
        }
      }
    }

    this.setState({
      allStates: this.state.allStates.concat(selectedArr).sort(),
    });

    //sending to redux to update
    this.props.selectedStatesList(
      this.state.allStates.concat(selectedArr).sort()
    );
  };

  render() {
    let statesList = this.state.allStates
      .filter((x) => !this.state.selectedStates.includes(x))
      .sort()
      .map((x, i) => (
        <option key={i} value={x}>
          {x}
        </option>
      ));

    let selectedTradeList = this.state.selectedStates.sort().map((x, i) =>
      x != '' ? (
        <option key={i} className="tradeList" value={x}>
          {x}
        </option>
      ) : (
        ''
      )
    );

    return (
      <div className="">
        <strong className="">Service Areas*</strong>
        <br />
        <div className="joined-list">
          <div className="select-list">
            <select className="form-select" multiple>
              {statesList}
            </select>
          </div>

          <div className="button-column">
            <button
              className="btn btn-sm btn-light-blue"
              onClick={this.addSelected}
            >
              {' '}
              <i className="bi bi-chevron-right"></i>
            </button>
            <br />
            <button
              className="btn btn-sm btn-orange"
              onClick={this.removeSelected}
            >
              {' '}
              <i className="bi bi-chevron-left"></i>
            </button>
          </div>

          <div className="select-list">
            <select
              className={`form-select ${
                !this.props.isInvalid ? '' : 'is-invalid'
              } `}
              multiple
            >
              {selectedTradeList}
            </select>
            <div className="invalid-feedback">
              Please select at least one Service Area
            </div>
          </div>
        </div>
      </div>
    );
  }
}
