import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import * as SubFormState from '../store/SubForm';
import {
  Trade,
  SubmitForm,
  SubFormVM,
  FormAttachment,
} from '../store/interface';
import { TradeListSelection } from './SubComponent/TradeListSelection';
import ServiceAreas from './SubComponent/ServiceAreas';
import * as download from 'downloadjs';
import { countriesShort, states, serviceAreas } from './constants';
import { SliderCheckbox } from './SubComponent/SliderCheckbox';
import { F40FormGroup, F40FormRow } from './f40form/F40Form';
import '../css/f40form.css';
import { useDispatch } from 'react-redux';
import { Modal2 } from './SubComponent/Modal';

export interface FormState {
  readonly vendorName: string;
  readonly state: string;
  readonly city: string;
  readonly zip: string;
  readonly billingAddress: string;

  readonly p_state: string;
  readonly p_city: string;
  readonly p_zip: string;
  readonly p_Address: string;

  readonly distributedEnergyMarket: boolean;
  readonly evMarket: boolean;
  readonly retailMarket: boolean;
  readonly evitpCertified: boolean;

  readonly contactName: string;
  readonly contactEmail: string;
  readonly contactTitle: string;
  readonly businessPhone: string;
  readonly alternativePhone: string;
  readonly referral: string;
  readonly notes: string;
  readonly union: string;
  readonly country: string;
  readonly p_country: string;
  readonly vendorType: string;
  readonly federalCertification: string[];
  //readonly files: string;
  //readonly acknowledgementForm: string;
  //readonly w9_Form: string;
  readonly passed: boolean;
  readonly trades: number;
  readonly bparr1: string;
  readonly bparr2: string;
  readonly bparr3: string;
  readonly zipOrexe: boolean;
  readonly sameAddress: boolean;

  readonly yearsInBusiness: number;
  readonly numberOfEmployees: number;
  readonly modRate3Years: string[];
  readonly hasSafetyPolicy: boolean;
  readonly vendorId: number;
  readonly w9Form: string;
  readonly sBEForm: string;
  readonly mBEForm: string;
  readonly wBEForm: string;
  readonly vBEForm: string;
  readonly uSBLNForm: string;
  readonly hZBEForm: string;
  readonly dBEForm: string;
  readonly dVBEForm: string;
  readonly lGBTBEForm: string;
  readonly isSubAcknowledged: boolean;
  readonly attachments: FormAttachment[];
}

const emptyForm: FormState = {
  vendorName: '2',
  state: '2',
  city: '2',
  zip: '2',
  billingAddress: '2',

  p_state: '2',
  p_city: '2',
  p_zip: '2',
  p_Address: '2',
  distributedEnergyMarket: false,
  evMarket: false,
  retailMarket: false,
  evitpCertified: false,

  contactName: '2',
  contactEmail: '2',
  contactTitle: '',
  businessPhone: '',
  alternativePhone: '',
  referral: '',
  notes: '',
  union: '2',
  country: 'United States of America',
  p_country: 'United States of America',
  federalCertification: [],
  vendorType: '',
  //files: "",
  //acknowledgementForm: "",
  passed: true,
  trades: 0,
  bparr1: '',
  bparr2: '',
  bparr3: '',
  zipOrexe: false,
  sameAddress: false,

  yearsInBusiness: 0,
  numberOfEmployees: 0,
  modRate3Years: ['0.0', '0.0', '0.0'],
  hasSafetyPolicy: false,
  vendorId: 0,
  w9Form: '',
  sBEForm: '',
  mBEForm: '',
  wBEForm: '',
  vBEForm: '',
  uSBLNForm: '',
  hZBEForm: '',
  dBEForm: '',
  dVBEForm: '',
  lGBTBEForm: '',
  isSubAcknowledged: false,
  attachments: [],
};

interface HomeProps {
  subForm?: SubFormVM;
}

export const Home = ({ subForm }: HomeProps) => {
  const [form, setForm] = React.useState<FormState>(emptyForm);
  const [removeAttachmentId, setRemoveAttachmentId] = React.useState(
    null as number | null
  );
  const subFormStore = useSelector((s: ApplicationState) => s.subForm);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(SubFormState.actionCreators.getTradeList());
  }, []);

  React.useEffect(() => {
    if (subForm) {
      const newForm: FormState = {
        vendorName: subForm.vendorName,
        state: subForm.state,
        city: subForm.city,
        zip: subForm.primaryZip,
        billingAddress: subForm.billingAddress,

        p_state: subForm.physicalState,
        p_city: subForm.physicalCity,
        p_zip: subForm.physicalZip,
        p_Address: subForm.physicalAddress,
        distributedEnergyMarket: subForm.distributedEnergyMarket,
        evMarket: subForm.evMarket,
        retailMarket: subForm.retailMarket,
        evitpCertified: subForm.evitpCertified,

        contactName: subForm.contactName,
        contactEmail: subForm.contactEmail,
        contactTitle: subForm.contactTitle,
        businessPhone: subForm.businessPhone,
        alternativePhone: subForm.altPhone,
        referral: subForm.referral,
        notes: subForm.notes,
        union: subForm.union,
        country: subForm.country,
        p_country: subForm.p_Country,
        federalCertification: subForm.federalCertification,
        vendorType: subForm.vendorType,
        passed: true,
        trades: subForm.trades.length,
        bparr1: '',
        bparr2: '',
        bparr3: '',
        zipOrexe: false,
        sameAddress: subForm.billingAddress == subForm.physicalAddress,

        yearsInBusiness: subForm.yearsInBusiness,
        numberOfEmployees: subForm.numberOfEmployees,
        modRate3Years: subForm.modRate3Years.map((x) => x ? x.toString() : '0'),
        hasSafetyPolicy: subForm.hasSafetyPolicy,
        vendorId: subForm.vendorId,
        w9Form: subForm.attachments.find((x) => x.type == 'W9')?.fileName ?? '',
        sBEForm:
          subForm.attachments.find((x) => x.type == 'SBE')?.fileName ?? '',
        mBEForm:
          subForm.attachments.find((x) => x.type == 'MBE')?.fileName ?? '',
        wBEForm:
          subForm.attachments.find((x) => x.type == 'WBE')?.fileName ?? '',
        vBEForm:
          subForm.attachments.find((x) => x.type == 'VBE')?.fileName ?? '',
        uSBLNForm:
          subForm.attachments.find((x) => x.type == 'USBLN')?.fileName ?? '',
        hZBEForm:
          subForm.attachments.find((x) => x.type == 'HZBE')?.fileName ?? '',
        dBEForm:
          subForm.attachments.find((x) => x.type == 'DBE')?.fileName ?? '',
        dVBEForm:
          subForm.attachments.find((x) => x.type == 'DVBE')?.fileName ?? '',
        lGBTBEForm:
          subForm.attachments.find((x) => x.type == 'LGBTBE')?.fileName ?? '',
        attachments: subForm.attachments,
        isSubAcknowledged:
          subForm.isSubAcknowledged
      };

      setForm(newForm);
    }
  }, [subForm]);

  const updateFormInput = (e: React.FormEvent<HTMLInputElement>) => {
    let val;
    if (e.currentTarget.type == 'checkbox') val = e.currentTarget.checked;
    else val = e.currentTarget.value;
    setForm({
      ...form,

      [e.currentTarget.name]: val,
    } as any);
  };

  const updateFileFormInput = (e: React.FormEvent<HTMLInputElement>) => {
    let filename = '';

    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      filename = e.currentTarget.files[0].name;
    }

    setForm({
      ...form,
      [e.currentTarget.name]: filename,
    } as any);
  };

  const updateFormTextArea = (e: React.FormEvent<HTMLTextAreaElement>) => {
    let val = e.currentTarget.value;
    setForm({
      ...form,

      [e.currentTarget.name]: val,
    } as any);
  };

  const updateModYears = (e: React.FocusEvent<HTMLInputElement>) => {
    const idx = e.currentTarget.getAttribute('data-index');
    if (idx) {
      const i = parseInt(idx);
      const newRates = form.modRate3Years.slice().map((x, j) => {
        return i === j ? e.currentTarget.value : x;
      });
      setForm({
        ...form,
        modRate3Years: newRates,
      });
    }
  };

  const updateFormDropDown = (e: React.FormEvent<HTMLSelectElement>) => {
    let a = e.currentTarget.parentElement!.getElementsByTagName(
      'select'
    )[0] as HTMLSelectElement;
    let b = Array.prototype.slice.call(a.options) as Array<HTMLOptionElement>;
    const c = b.filter((x) => x.selected)[0].innerText;

    switch (e.currentTarget.name) {
      case 'vendorType':
      case 'federalCertification':
        if (c == '-- Please make a selection --') {
          break;
        } else {
          setForm({
            ...form,

            [e.currentTarget.name]: c,
          } as any);
        }
        break;
      default:
        setForm({
          ...form,

          [e.currentTarget.name]: c,
        } as any);
        break;
    }
  };

  const saveSubmitButton = () => {
    const newForm: any = { ...form };

    // if the physical address is the same as the billing address
    if (form.sameAddress) {
      newForm.p_state = form.state.trim();
      newForm.p_city = form.city.trim();
      newForm.p_zip = form.zip;
      newForm.p_Address = form.billingAddress.trim();
      newForm.p_country = form.country.trim();
    } else {
      newForm.p_state = form.p_state.trim();
      newForm.p_city = form.p_city.trim();
      newForm.p_zip = form.p_zip;
      newForm.p_Address = form.p_Address.trim();
      newForm.p_country = form.p_country.trim();
    }

    //iterate through the form and collect the properties that are empty
    const emptyInput = [];
    //for (var key in newForm) {
    //  if (newForm[key] === '2') {
    //    // @ts-ignore
    //    emptyInput.push(key);
    //  }
    //}

    if (
      vendorName &&
      city &&
      state &&
      zip &&
      billingAddress &&
      (!form.sameAddress ? p_city && p_state && p_zip && p_Address : true) &&
      contactName &&
      contactEmail &&
      businessPhone &&
      union &&
      w9Form &&
      sBEForm &&
      mBEForm &&
      wBEForm &&
      vBEForm &&
      uSBLNForm &&
      hZBEForm &&
      dBEForm &&
      dVBEForm &&
      lGBTBEForm &&
      yearsInBusiness &&
      numberOfEmployees
    ) {
      if (emptyInput.length === 0) {
        const input = document.getElementById('w9Form') as HTMLInputElement;
        if (input && input.files) {
          newForm.w9Form = input.files[0];
        } else {
          newForm.w9Form = null;
        }

        if (form.sBEForm?.length > 0) {
          const input = document.getElementById('sBEForm') as HTMLInputElement;
          if (input && input.files) {
            newForm.sBEForm = input.files[0];
          } else {
            newForm.sBEForm = null;
          }
        }

        if (form.mBEForm?.length > 0) {
          const input = document.getElementById('mBEForm') as HTMLInputElement;
          if (input && input.files) {
            newForm.mBEForm = input.files[0];
          } else {
            newForm.mBEForm = null;
          }
        }

        if (form.wBEForm?.length > 0) {
          const input = document.getElementById('wBEForm') as HTMLInputElement;
          if (input && input.files) {
            newForm.wBEForm = input.files[0];
          } else {
            newForm.wBEForm = null;
          }
        }

        if (form.vBEForm?.length > 0) {
          const input = document.getElementById('vBEForm') as HTMLInputElement;
          if (input && input.files) {
            newForm.vBEForm = input.files[0];
          } else {
            newForm.vBEForm = null;
          }
        }

        if (form.uSBLNForm?.length > 0) {
          const input = document.getElementById(
            'uSBLNForm'
          ) as HTMLInputElement;
          if (input && input.files) {
            newForm.uSBLNForm = input.files[0];
          } else {
            newForm.uSBLNForm = null;
          }
        }

        if (form.hZBEForm?.length > 0) {
          const input = document.getElementById('hZBEForm') as HTMLInputElement;
          if (input && input.files) {
            newForm.hZBEForm = input.files[0];
          } else {
            newForm.hZBEForm = null;
          }
        }

        if (form.dBEForm?.length > 0) {
          const input = document.getElementById('dBEForm') as HTMLInputElement;
          if (input && input.files) {
            newForm.dBEForm = input.files[0];
          } else {
            newForm.dBEForm = null;
          }
        }

        if (form.dVBEForm?.length > 0) {
          const input = document.getElementById('dVBEForm') as HTMLInputElement;
          if (input && input.files) {
            newForm.dVBEForm = input.files[0];
          } else {
            newForm.dVBEForm = null;
          }
        }

        if (form.lGBTBEForm?.length > 0) {
          const input = document.getElementById(
            'lGBTBEForm'
          ) as HTMLInputElement;
          if (input && input.files) {
            newForm.lGBTBEForm = input.files[0];
          } else {
            newForm.lGBTBEForm = null;
          }
        }

        setForm({ ...newForm, passed: true });
        dispatch(
          SubFormState.actionCreators.submitForm(
            newForm,
            subForm ? true : false
          )
        );
      } else {
        setForm({
          ...form,
          passed: false,
        });
      }
    } else {
      setForm({
        ...form,
        passed: false,
      });
    }
  };
  // to count the number of trades selected. In case the user added and removed couple times
  const updateSelectedIntoHome = (e: Trade[], plusMinus: number) => {
    plusMinus == 1
      ? setForm({ ...form, trades: form.trades + e.length })
      : setForm({ ...form, trades: form.trades - e.length });
  };

  const teleInput = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length < 11) {
      setForm({
        ...form,
        [e.currentTarget.name]: e.currentTarget.value,
      } as any);
    }
  };

  const handleCheckbox = (e: React.FormEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;

    let a = form.federalCertification.filter((x) => x == v);

    if (a.length % 2 != 0) {
      const b = form.federalCertification.filter((x) => x != v);
      setForm({
        ...form,
        federalCertification: b,
      });
    } else {
      const joined = form.federalCertification.concat(v);

      setForm({
        ...form,
        federalCertification: joined,
      });
    }
  };

  const sameAddressCheckBox = () => {
    let checked = form.sameAddress;
    setForm({
      ...form,
      sameAddress: !checked,
    });
  };

  const listCountries = countriesShort.map((x, i) => (
    <option key={i}>{x}</option>
  ));

  const listStates = React.useMemo(() => {
    return states.map((x, i) => (
      <option
        value={x}
        //selected={x.toLowerCase() == subForm?.state.toLowerCase()}
        key={i}
      >
        {x}
      </option>
    ));
  }, [subForm]);
  const listStatesPhysical = React.useMemo(() => {
    return states.map((x, i) => (
      <option
        value={x}
        //selected={x.toLowerCase() == subForm?.physicalState.toLowerCase()}
        key={i}
      >
        {x}
      </option>
    ));
  }, [subForm]);

  let //file = form.files.length > 0 ? "" : "missing-file-error",
    vendorName = form.vendorName.length > 1,
    city = form.city.length > 1,
    state = form.state.length > 1,
    zip = form.zip.length > 1 || form.country !== 'United States of America',
    billingAddress = form.billingAddress.length > 1,
    p_city = form.p_city.length > 1,
    p_state = form.p_state.length > 1,
    p_zip =
      form.p_zip.length > 1 || form.p_country !== 'United States of America',
    p_Address = form.p_Address.length > 1,
    contactName = form.contactName.length > 1,
    contactEmail = form.contactEmail.length > 1,
    businessPhone = form.businessPhone.length > 1,
    union = form.union.length > 1,
    w9Form = form.w9Form?.length > 0,
    sBEForm = form.federalCertification.includes(
      'Small Business Enterprise (SBE)'
    )
      ? form.sBEForm?.length > 0
      : true,
    mBEForm = form.federalCertification.includes(
      'Minority Owned Business Enterprise (MBE)'
    )
      ? form.mBEForm?.length > 0
      : true,
    wBEForm = form.federalCertification.includes(
      'Woman Owned Business Enterprise (WBE)'
    )
      ? form.wBEForm?.length > 0
      : true,
    vBEForm = form.federalCertification.includes(
      'Veteran Owned Business Enterprise (VBE)'
    )
      ? form.vBEForm?.length > 0
      : true,
    uSBLNForm = form.federalCertification.includes(
      'People with Disabilities (USBLN)'
    )
      ? form.uSBLNForm?.length > 0
      : true,
    hZBEForm = form.federalCertification.includes(
      'HUB Zone Business Enterprise (HZBE)'
    )
      ? form.hZBEForm?.length > 0
      : true,
    dBEForm = form.federalCertification.includes(
      'Small Disadvantaged Busniess Enterprise (DBE)'
    )
      ? form.dBEForm?.length > 0
      : true,
    dVBEForm = form.federalCertification.includes(
      'Service-Disabled Veteran Owned Business Enterprise (DVBE)'
    )
      ? form.dVBEForm?.length > 0
      : true,
    lGBTBEForm = form.federalCertification.includes(
      'Lesbian, Gay, Bisexual and/or Transgender Owned Business Enterprise (LGBTBE)'
    )
      ? form.lGBTBEForm?.length > 0
      : true,
    yearsInBusiness = form.yearsInBusiness > 0,
    numberOfEmployees = form.numberOfEmployees > 0,
    generalDescription = form.notes.length > 0;

  const modalWarning = () => {
    return (
      <div className="container blackback">
        <div className="modal show" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div style={{ justifyContent: 'end' }} className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  onClick={() => {
                    dispatch(SubFormState.actionCreators.modalButton());
                  }}
                >
                  &times;
                </button>
                <h4 className="modal-title gFont">Error: </h4>
              </div>
              <div className="modal-body gFont">
                <p>A record for this subcontract exists.</p>
                <br />
                <p>
                  Please contact a CoreStates representative for more
                  information.
                </p>
                <p>Thank You</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-dismiss="modal"
                  onClick={() => {
                    dispatch(SubFormState.actionCreators.modalButton());
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const modal_Submitted = () => {
    return (
      <div className="container blackback">
        <div className="modal show" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div style={{ justifyContent: 'end' }} className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  onClick={() => {
                    dispatch(SubFormState.actionCreators.modalButton());
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body mb gFont">
                <h1 className="bi bi-check-circle"></h1>
                <h1>Thank You</h1>
                <br />
                <h2>{`The form was ${subForm ? 'saved' : 'submitted'
                  } successfully`}</h2>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-dismiss="modal"
                  onClick={() => {
                    dispatch(SubFormState.actionCreators.modalButton());
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _removeAttachment = () => {
    fetch(`api/SubForm/removeattachment?attachmentId=${removeAttachmentId}`, {
      method: 'DELETE',
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk && subForm) {
          dispatch(SubFormState.actionCreators.getVendorForm(subForm.vendorId));
          setRemoveAttachmentId(null);
        }
      });
  };

  const removeAttachmentComponent = removeAttachmentId ? (
    <Modal2 dialogClass="white">
      <div className="modal-body" style={{ textAlign: 'center' }}>
        Are you sure you want to delete this attachment?
      </div>
      <div className="modal-footer">
        <button className="btn btn-sm btn-danger" onClick={_removeAttachment}>
          Delete
        </button>
        <button
          className="btn btn-sm btn-secondary"
          onClick={() => setRemoveAttachmentId(null)}
        >
          Cancel
        </button>
      </div>
    </Modal2>
  ) : (
    []
  );

  return (
    <div>
      {removeAttachmentComponent}
      <div className="container">
        {/*<h1 className="gFont" style={{ marginLeft: '15px' }}><strong>New Vendor / Subcontractor</strong></h1>*/}
        <br />
        <small style={{ marginLeft: '0px' }}>
          * indicates a required field
        </small>
        <F40FormRow>
          <F40FormGroup noFlex width={'50%'}>
            <div>
              <b>Company Name*</b>
            </div>
            <input
              className={`form-control ${vendorName || form.passed ? '' : 'is-invalid'
                } `}
              disabled={subForm ? true : false}
              name="vendorName"
              onBlur={updateFormInput}
              defaultValue={subForm ? subForm.vendorName : ''}
            />
            {vendorName || form.passed ? (
              ''
            ) : (
              <div className="invalid-feedback">
                Please provide company name
              </div>
            )}
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup noFlex width={'50%'}>
            <div>
              <b>Billing Address*</b>
            </div>
            <input
              className={`form-control ${billingAddress || form.passed ? '' : 'is-invalid'
                } `}
              name="billingAddress"
              onBlur={updateFormInput}
              defaultValue={subForm ? subForm.billingAddress : ''}
            />
            {billingAddress || form.passed ? (
              ''
            ) : (
              <div className="invalid-feedback">Please fill in an address</div>
            )}
          </F40FormGroup>
          <F40FormGroup noFlex width={'25%'}>
            <div>
              <b>City*</b>
            </div>
            <input
              className={`form-control ${city || form.passed ? '' : 'is-invalid'
                } `}
              name="city"
              onBlur={updateFormInput}
              defaultValue={subForm ? subForm.city : ''}
            />
            {city || form.passed ? (
              ''
            ) : (
              <div className="invalid-feedback">Please provide a city</div>
            )}
          </F40FormGroup>
          <F40FormGroup noFlex width={'25%'}>
            <div>
              <b>State/Province*</b>
            </div>
            <select
              className={`form-select ${state || form.passed ? '' : 'is-invalid'
                } `}
              name="state"
              placeholder="State"
              defaultValue={subForm ? subForm.state : ''}
              onBlur={updateFormDropDown}
            >
              <option></option>
              {listStates}
            </select>
            {state || form.passed ? (
              ''
            ) : (
              <div className="invalid-feedback">
                Please select a state/province
              </div>
            )}
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup noFlex width={'50%'}>
            <div>
              <b>Country*</b>
            </div>
            <select
              className="form-select"
              name="country"
              placeholder="Country"
              onBlur={updateFormDropDown}
              defaultValue={subForm ? subForm.country : ''}
            >
              {listCountries}
            </select>
          </F40FormGroup>
          <F40FormGroup noFlex width={'25%'}>
            <div>
              <b>Zip{form.country === 'United States of America' && '*'}</b>
            </div>
            <input
              className={`form-control ${zip || form.passed ? '' : 'is-invalid'
                } `}
              onBlur={updateFormInput}
              name="zip"
              maxLength={10}
              defaultValue={subForm ? subForm.primaryZip : ''}
            />
            {zip || form.passed ? (
              ''
            ) : (
              <div className="invalid-feedback">Please provide a zipcode</div>
            )}
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width={'600px'}>
            {/*<input type="checkbox" onClick={sameAddressCheckBox} />*/}
            <b>Physical Address is the same as Billing Address</b> &nbsp; &nbsp;
            <SliderCheckbox
              onChange={sameAddressCheckBox}
              selected={
                subForm
                  ? subForm.billingAddress == subForm.physicalAddress
                  : false
              }
              fieldName=""
            />
            &nbsp; &nbsp; &nbsp; &nbsp;
          </F40FormGroup>
        </F40FormRow>
        {form.sameAddress ? (
          ''
        ) : (
          <>
            <F40FormRow>
              <F40FormGroup noFlex width={'50%'}>
                <div>
                  <b>Physical Address*</b>
                </div>
                <input
                  className={`form-control ${p_Address || form.passed ? '' : 'is-invalid'
                    } `}
                  name="p_Address"
                  onBlur={updateFormInput}
                  defaultValue={subForm ? subForm.physicalAddress : ''}
                />
                {p_Address || form.passed ? (
                  ''
                ) : (
                  <div className="invalid-feedback">
                    Please fill in an address
                  </div>
                )}
              </F40FormGroup>
              <F40FormGroup noFlex width={'25%'}>
                <div>
                  <b>City*</b>
                </div>
                <input
                  className={`form-control ${p_city || form.passed ? '' : 'is-invalid'
                    } `}
                  name="p_city"
                  onBlur={updateFormInput}
                  defaultValue={subForm ? subForm.physicalCity : ''}
                />
                {p_city || form.passed ? (
                  ''
                ) : (
                  <div className="invalid-feedback">Please provide a city</div>
                )}
              </F40FormGroup>
              <F40FormGroup noFlex width={'25%'}>
                <div>
                  <b>State/Province*</b>
                </div>
                <select
                  className={`form-select ${p_state || form.passed ? '' : 'is-invalid'
                    } `}
                  name="p_state"
                  placeholder="State"
                  onBlur={updateFormDropDown}
                  defaultValue={subForm ? subForm.physicalState : ''}
                >
                  <option></option>
                  {listStatesPhysical}
                </select>
                {p_state || form.passed ? (
                  ''
                ) : (
                  <div className="invalid-feedback">
                    Please select a state/province
                  </div>
                )}
              </F40FormGroup>
            </F40FormRow>
            <F40FormRow>
              <F40FormGroup noFlex width={'50%'}>
                <div>
                  <b>Country</b>
                </div>
                <select
                  className="form-select"
                  name="p_country"
                  placeholder="Country"
                  onBlur={updateFormDropDown}
                  defaultValue={subForm ? subForm.p_Country : ''}
                >
                  {listCountries}
                </select>
              </F40FormGroup>
              <F40FormGroup noFlex width={'25%'}>
                <div>
                  <b>
                    Zip
                    {form.p_country === 'United States of America' && '*'}
                  </b>
                </div>
                <input
                  className={`form-control ${p_zip || form.passed ? '' : 'is-invalid'
                    } `}
                  onBlur={updateFormInput}
                  name="p_zip"
                  maxLength={10}
                  defaultValue={subForm ? subForm.physicalZip : ''}
                />

                {p_zip || form.passed ? (
                  ''
                ) : (
                  <div className="invalid-feedback">
                    Please provide a zipcode
                  </div>
                )}
              </F40FormGroup>
            </F40FormRow>
          </>
        )}
        <F40FormRow>
          <F40FormGroup noFlex width={'50%'}>
            <div>
              <b>Market Sectors* (select all that apply)</b>
            </div>
            <div style={{ height: '10px', width: '100%' }}></div>
            <div style={{ display: 'flex' }}>
              <b style={{ width: '130px' }}>Distributed Energy</b>
              <SliderCheckbox
                onChange={updateFormInput}
                selected={subForm ? subForm.distributedEnergyMarket : false}
                fieldName="distributedEnergyMarket"
              />
              &nbsp; &nbsp; &nbsp; &nbsp;
              <b>EV</b> &nbsp;
              <SliderCheckbox
                onChange={updateFormInput}
                selected={subForm ? subForm.evMarket : false}
                fieldName="evMarket"
              />
              &nbsp; &nbsp; &nbsp; &nbsp;
              <b>Retail/Commercial</b> &nbsp;
              <SliderCheckbox
                onChange={updateFormInput}
                selected={subForm ? subForm.retailMarket : false}
                fieldName="retailMarket"
              />
              &nbsp;
            </div>
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup noFlex width={'100%'}>
            <div style={{ display: 'flex' }}>
              <b style={{ width: '130px' }}>EVITP Certified</b>
              <SliderCheckbox
                onChange={updateFormInput}
                selected={subForm ? subForm.evitpCertified : false}
                fieldName="evitpCertified"
              />
            </div>
          </F40FormGroup>
        </F40FormRow>

        {/*</div>*/}
        {/*<div className="panel panel-default">*/}
        <F40FormRow>
          <F40FormGroup noFlex width={'50%'}>
            {/*<div style={{ width: '60%' }}>*/}
            {/* @ts-ignore */}
            <TradeListSelection
              allTradeList={subFormStore.trade}
              selectedList={subFormStore.selectedTrade}
              updateList={(selected) => {
                dispatch(
                  SubFormState.actionCreators.selectedTradeList(selected)
                );
              }}
              removeSelectedTradeList={(selected) => {
                dispatch(
                  SubFormState.actionCreators.removeSelectedTradeList(selected)
                );
              }}
              selectedTrades={updateSelectedIntoHome}
              isInvalid={!(form.trades > 0 || form.passed)}
            />

            {/*</div>*/}
          </F40FormGroup>
          {/*</div>*/}
          {/*<div className="panel panel-default">*/}
          <F40FormGroup noFlex width={'50%'}>
            {/*<div style={{ width: '60%' }}>*/}
            <ServiceAreas
              selectedStatesList={(selected) =>
                dispatch(
                  SubFormState.actionCreators.selectedStatesList(selected)
                )
              }
              selectedStates={subFormStore.selectedState}
              isInvalid={
                !(subFormStore.selectedState.length > 0 || form.passed)
              }
            />
            {/*</div>*/}
          </F40FormGroup>
        </F40FormRow>
        <div>
          <F40FormRow>
            <F40FormGroup noFlex width={'33%'}>
              <div>
                <b>Contact Name*</b>
              </div>
              <input
                className={`form-control ${contactName || form.passed ? '' : 'is-invalid'
                  } `}
                name="contactName"
                onBlur={updateFormInput}
                defaultValue={subForm ? subForm.contactName : ''}
              />

              {contactName || form.passed ? (
                ''
              ) : (
                <div className="invalid-feedback">
                  Please provide a contact name
                </div>
              )}
            </F40FormGroup>
            <F40FormGroup noFlex width={'33%'}>
              <div>
                <b>Contact Email*</b>
              </div>
              <input
                className={`form-control ${contactEmail || form.passed ? '' : 'is-invalid'
                  } `}
                name="contactEmail"
                onBlur={updateFormInput}
                placeholder="example@mydomain.com"
                defaultValue={subForm ? subForm.contactEmail : ''}
              />

              {contactEmail || form.passed ? (
                ''
              ) : (
                <div className="invalid-feedback">
                  Please provide a contact email
                </div>
              )}
            </F40FormGroup>
            <F40FormGroup noFlex width={'33%'}>
              <div>
                <b>Contact Title</b>
              </div>
              <input
                className="form-control"
                name="contactTitle"
                onBlur={updateFormInput}
                defaultValue={subForm ? subForm.contactTitle : ''}
              />
            </F40FormGroup>
          </F40FormRow>
          <F40FormRow>
            <F40FormGroup noFlex width={'33%'}>
              <div>
                <b>Business Phone*</b>
              </div>
              <input
                className={`form-control ${businessPhone || form.passed ? '' : 'is-invalid'
                  } `}
                key={1}
                name="businessPhone"
                defaultValue={subForm ? subForm.businessPhone : ''}
                onChange={teleInput}
              />
              {businessPhone || form.passed ? (
                ''
              ) : (
                <div className="invalid-feedback">
                  Please fill in a phone number
                </div>
              )}
            </F40FormGroup>
            <F40FormGroup noFlex width={'33%'}>
              <div>
                <b>Alternative / Mobile Phone</b>
              </div>
              <input
                className="form-control"
                key={2}
                name="alternativePhone"
                onChange={teleInput}
                //value={form.alternativePhone}
                defaultValue={subForm ? subForm.altPhone : ''}
              />
            </F40FormGroup>
          </F40FormRow>
          <F40FormRow style={{ display: 'flex' }}>
            <F40FormGroup noFlex width={'33%'}>
              <div>
                <b>Years In Business*</b>
              </div>
              <input
                className={`form-control ${yearsInBusiness || form.passed ? '' : 'is-invalid'
                  } `}
                type="number"
                onChange={updateFormInput}
                name="yearsInBusiness"
                defaultValue={subForm ? subForm.yearsInBusiness.toString() : ''}
              />
              {yearsInBusiness || form.passed ? (
                ''
              ) : (
                <div className="invalid-feedback">
                  Please fill in valid year amount
                </div>
              )}
            </F40FormGroup>
            <F40FormGroup noFlex width={'33%'}>
              <div>
                <b>Number of Employees*</b>
              </div>
              <input
                className={`form-control ${numberOfEmployees || form.passed ? '' : 'is-invalid'
                  } `}
                type="number"
                onChange={updateFormInput}
                name="numberOfEmployees"
                defaultValue={
                  subForm ? subForm.numberOfEmployees.toString() : ''
                }
              />
              {numberOfEmployees || form.passed ? (
                ''
              ) : (
                <div className="invalid-feedback">
                  Please fill in valid number
                </div>
              )}
            </F40FormGroup>
            <F40FormGroup
              noFlex
              width={'33%'}
              style={{ position: 'relative', paddingRight: '0px', display: 'block' }}
            >
              <div>
                <b>Mod Rate Last 3 Years</b>
              </div>
              <div style={{ position: 'relative', width: '100%', marginBottom: '0.5em' }}>
                <div style={{ position: 'absolute', left: '-35px', top: '3px' }}>{`${new Date().getFullYear() - 1}`}</div>
                <input
                  className="form-control"
                  type="number"
                  max="2.0"
                  min="0.00"
                  data-index="0"
                  onBlur={updateModYears}
                  defaultValue={
                    subForm ? subForm.modRate3Years[0]?.toString() : ''
                  }
                />
              </div>              
              <div style={{ position: 'relative', width: '100%', marginBottom: '0.5em' }}>
                <span style={{ position: 'absolute', left: '-35px', top: '3px' }}>{`${new Date().getFullYear() - 2}`}</span>
                <input
                  className="form-control"
                  type="number"
                  max="2.0"
                  min="0.00"
                  data-index="1"
                  onBlur={updateModYears}
                  defaultValue={
                    subForm ? subForm.modRate3Years[1]?.toString() : ''
                  }                  
                />
              </div>             
              <div style={{ position: 'relative', width: '100%' }}>
                <span style={{ position: 'absolute', left: '-35px', top: '3px' }}>{`${new Date().getFullYear() - 3}`}</span>
                <input
                  className="form-control"
                  type="number"
                  max="2.0"
                  min="0.00"
                  data-index="2"
                  onBlur={updateModYears}
                  defaultValue={
                    subForm ? subForm.modRate3Years[2]?.toString() : ''
                  }
                />
              </div>
            </F40FormGroup>
          </F40FormRow>
          <F40FormRow>
            <F40FormGroup noFlex width={'25%'}>
              <div>
                <b>Do you have a written Safety Policy?</b>
              </div>
              <SliderCheckbox
                onChange={updateFormInput}
                selected={subForm ? subForm.hasSafetyPolicy : false}
                fieldName="hasSafetyPolicy"
              />
            </F40FormGroup>
          </F40FormRow>
        </div>

        <div className="">
          <F40FormRow>
            <F40FormGroup noFlex width={'33%'}>
              <div>
                <b>Vendor Type</b>
              </div>
              <select
                className="form-select"
                name="vendorType"
                onChange={updateFormDropDown}
                defaultValue={subForm ? subForm.vendorType : ''}
              >
                <option value=''>-- Please make a selection --</option>
                <option value='Subcontractor'>Subcontractor</option>
                <option value='Material Supplier'>Material Supplier</option>
                <option value='Both'>Both</option>
              </select>
            </F40FormGroup>
            <F40FormGroup noFlex width={'33%'}>
              <div>
                <b>Labor Status*</b>
              </div>
              <select
                className={`form-select ${union || form.passed ? '' : 'is-invalid'
                  } `}
                name="union"
                onBlur={updateFormDropDown}
                defaultValue={subForm ? subForm.union : ''}
              >
                <option value="">-- Please make a selection --</option>
                <option value="Open Shop">Open Shop</option>
                <option value="Merit">Merit</option>
                <option value="Non-Union">Non-Union</option>
                <option value="Union">Union</option>
              </select>

              {union || form.passed ? (
                ''
              ) : (
                <div className="invalid-feedback">
                  Please select labor status
                </div>
              )}
            </F40FormGroup>
            <F40FormGroup noFlex width={'33%'}>
              <div>
                <b>General Description of Services*</b>
              </div>
              <textarea
                className={`form-control ${generalDescription || form.passed ? '' : 'is-invalid'
                  } `}
                name="notes"
                onBlur={updateFormTextArea}
                defaultValue={subForm ? subForm.notes : ''}
                rows={3}
              />
              {generalDescription || form.passed ? (
                ''
              ) : (
                <div className="invalid-feedback">
                  Please provide a description
                </div>
              )}
            </F40FormGroup>
          </F40FormRow>
          <F40FormRow>
            <F40FormGroup noFlex width="55%">
              <b style={{ width: '350px' }}>Please upload your W9*</b>
              {form.attachments.find((x) => x.type == 'W9') ? (
                <p>
                  <a
                    href={
                      form.attachments.find((x) => x.type == 'W9')?.filePath
                    }
                    target="_blank"
                  >
                    {form.attachments.find((x) => x.type == 'W9')?.fileName}{' '}
                  </a>
                </p>
              ) : (
                <>
                  <input
                    className={`form-control ${w9Form || form.passed ? '' : 'is-invalid'
                      } `}
                    type="file"
                    id="w9Form"
                    name="w9Form"
                    accept=".pdf"
                    onChange={updateFileFormInput}
                  />
                  {w9Form || form.passed ? (
                    ''
                  ) : (
                    <div className="invalid-feedback">
                      Please submit your W9 form
                    </div>
                  )}
                </>
              )}
            </F40FormGroup>
          </F40FormRow>
          <F40FormRow>
            <div>
              <div style={{ width: '825px' }}>
                <strong>Federal Certification (select all that apply)</strong>
                <br />
                <F40FormGroup
                  style={{ display: 'inline-flex', alignItems: 'baseline' }}
                  width="100%"
                >
                  <input
                    onChange={(e) => handleCheckbox(e)}
                    type="checkbox"
                    name="hello"
                    value="Small Business Enterprise (SBE)"
                    disabled={form.attachments.some((x) => x.type == 'SBE')}
                    checked={form.federalCertification.includes(
                      'Small Business Enterprise (SBE)'
                    )}
                  />
                  <label className="cert-label">
                    Small Business Enterprise (SBE)
                  </label>
                  {form.attachments.some((x) => x.type == 'SBE') ? (
                    <div style={{ width: '100%' }}>
                      <a
                        style={{ marginRight: '8px' }}
                        href={
                          form.attachments.find((x) => x.type == 'SBE')
                            ?.filePath
                        }
                        target="_blank"
                      >
                        {
                          form.attachments.find((x) => x.type == 'SBE')
                            ?.fileName
                        }
                      </a>
                      <i
                        className="bi bi-trash"
                        onClick={() =>
                          setRemoveAttachmentId(
                            form.attachments.find((x) => x.type == 'SBE')?.id ??
                            null
                          )
                        }
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        title="Remove attachment"
                      ></i>
                    </div>
                  ) : (
                    form.federalCertification.includes(
                      'Small Business Enterprise (SBE)'
                    ) && (
                      <div>
                        <input
                          className={sBEForm || form.passed ? '' : 'is-invalid'}
                          type="file"
                          id="sBEForm"
                          name="sBEForm"
                          accept=".pdf"
                          onChange={updateFileFormInput}
                        />
                        {sBEForm || form.passed ? (
                          ''
                        ) : (
                          <div className="invalid-feedback">
                            Please submit valid certification
                          </div>
                        )}
                      </div>
                    )
                  )}
                </F40FormGroup>
                <br />
                <F40FormGroup
                  style={{ display: 'inline-flex', alignItems: 'baseline' }}
                  width="100%"
                >
                  <input
                    onChange={(e) => handleCheckbox(e)}
                    type="checkbox"
                    value="Minority Owned Business Enterprise (MBE)"
                    disabled={form.attachments.some((x) => x.type == 'MBE')}
                    checked={form.federalCertification.includes(
                      'Minority Owned Business Enterprise (MBE)'
                    )}
                  />
                  <label className="cert-label">
                    Minority Owned Business Enterprise (MBE)
                  </label>
                  {form.attachments.some((x) => x.type == 'MBE') ? (
                    <div style={{ width: '100%' }}>
                      <a
                        style={{ marginRight: '8px' }}
                        href={
                          form.attachments.find((x) => x.type == 'MBE')
                            ?.filePath
                        }
                        target="_blank"
                      >
                        {
                          form.attachments.find((x) => x.type == 'MBE')
                            ?.fileName
                        }
                      </a>
                      <i
                        className="bi bi-trash"
                        onClick={() =>
                          setRemoveAttachmentId(
                            form.attachments.find((x) => x.type == 'MBE')?.id ??
                            null
                          )
                        }
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        title="Remove attachment"
                      ></i>
                    </div>
                  ) : (
                    form.federalCertification.includes(
                      'Minority Owned Business Enterprise (MBE)'
                    ) && (
                      <div>
                        <input
                          className={mBEForm || form.passed ? '' : 'is-invalid'}
                          type="file"
                          id="mBEForm"
                          name="mBEForm"
                          accept=".pdf"
                          onChange={updateFileFormInput}
                        />
                        {mBEForm || form.passed ? (
                          ''
                        ) : (
                          <div className="invalid-feedback">
                            Please submit valid certification
                          </div>
                        )}
                      </div>
                    )
                  )}
                </F40FormGroup>
                <br />
                <F40FormGroup
                  style={{ display: 'inline-flex', alignItems: 'baseline' }}
                  width="100%"
                >
                  <input
                    onChange={(e) => handleCheckbox(e)}
                    type="checkbox"
                    value="Woman Owned Business Enterprise (WBE)"
                    disabled={form.attachments.some((x) => x.type == 'WBE')}
                    checked={form.federalCertification.includes(
                      'Woman Owned Business Enterprise (WBE)'
                    )}
                  />
                  <label className="cert-label">
                    Woman Owned Business Enterprise (WBE)
                  </label>
                  {form.attachments.some((x) => x.type == 'WBE') ? (
                    <div style={{ width: '100%' }}>
                      <a
                        style={{ marginRight: '8px' }}
                        href={
                          form.attachments.find((x) => x.type == 'WBE')
                            ?.filePath
                        }
                        target="_blank"
                      >
                        {
                          form.attachments.find((x) => x.type == 'WBE')
                            ?.fileName
                        }
                      </a>
                      <i
                        className="bi bi-trash"
                        onClick={() =>
                          setRemoveAttachmentId(
                            form.attachments.find((x) => x.type == 'WBE')?.id ??
                            null
                          )
                        }
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        title="Remove attachment"
                      ></i>
                    </div>
                  ) : (
                    form.federalCertification.includes(
                      'Woman Owned Business Enterprise (WBE)'
                    ) && (
                      <div>
                        <input
                          className={wBEForm || form.passed ? '' : 'is-invalid'}
                          type="file"
                          id="wBEForm"
                          name="wBEForm"
                          accept=".pdf"
                          onChange={updateFileFormInput}
                        />
                        {wBEForm || form.passed ? (
                          ''
                        ) : (
                          <div className="invalid-feedback">
                            Please submit valid certification
                          </div>
                        )}
                      </div>
                    )
                  )}
                </F40FormGroup>
                <br />
                <F40FormGroup
                  style={{ display: 'inline-flex', alignItems: 'baseline' }}
                  width="100%"
                >
                  <input
                    onChange={(e) => handleCheckbox(e)}
                    type="checkbox"
                    value="Veteran Owned Business Enterprise (VBE)"
                    disabled={form.attachments.some((x) => x.type == 'VBE')}
                    checked={form.federalCertification.includes(
                      'Veteran Owned Business Enterprise (VBE)'
                    )}
                  />
                  <label className="cert-label">
                    Veteran Owned Business Enterprise (VBE)
                  </label>
                  {form.attachments.some((x) => x.type == 'VBE') ? (
                    <div style={{ width: '100%' }}>
                      <a
                        style={{ marginRight: '8px' }}
                        href={
                          form.attachments.find((x) => x.type == 'VBE')
                            ?.filePath
                        }
                        target="_blank"
                      >
                        {
                          form.attachments.find((x) => x.type == 'VBE')
                            ?.fileName
                        }
                      </a>
                      <i
                        className="bi bi-trash"
                        onClick={() =>
                          setRemoveAttachmentId(
                            form.attachments.find((x) => x.type == 'VBE')?.id ??
                            null
                          )
                        }
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        title="Remove attachment"
                      ></i>
                    </div>
                  ) : (
                    form.federalCertification.includes(
                      'Veteran Owned Business Enterprise (VBE)'
                    ) && (
                      <div>
                        <input
                          className={vBEForm || form.passed ? '' : 'is-invalid'}
                          type="file"
                          id="vBEForm"
                          name="vBEForm"
                          accept=".pdf"
                          onChange={updateFileFormInput}
                        />
                        {vBEForm || form.passed ? (
                          ''
                        ) : (
                          <div className="invalid-feedback">
                            Please submit valid certification
                          </div>
                        )}
                      </div>
                    )
                  )}
                </F40FormGroup>
                <br />
                <F40FormGroup
                  style={{ display: 'inline-flex', alignItems: 'baseline' }}
                  width="100%"
                >
                  <input
                    onChange={(e) => handleCheckbox(e)}
                    type="checkbox"
                    value="People with Disabilities (USBLN)"
                    disabled={form.attachments.some((x) => x.type == 'USBLN')}
                    checked={form.federalCertification.includes(
                      'People with Disabilities (USBLN)'
                    )}
                  />
                  <label className="cert-label">
                    People with Disabilities (USBLN)
                  </label>
                  {form.attachments.some((x) => x.type == 'USBLN') ? (
                    <div style={{ width: '100%' }}>
                      <a
                        style={{ marginRight: '8px' }}
                        href={
                          form.attachments.find((x) => x.type == 'USBLN')
                            ?.filePath
                        }
                        target="_blank"
                      >
                        {
                          form.attachments.find((x) => x.type == 'USBLN')
                            ?.fileName
                        }
                      </a>
                      <i
                        className="bi bi-trash"
                        onClick={() =>
                          setRemoveAttachmentId(
                            form.attachments.find((x) => x.type == 'USBLN')
                              ?.id ?? null
                          )
                        }
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        title="Remove attachment"
                      ></i>
                    </div>
                  ) : (
                    form.federalCertification.includes(
                      'People with Disabilities (USBLN)'
                    ) && (
                      <div>
                        <input
                          className={
                            uSBLNForm || form.passed ? '' : 'is-invalid'
                          }
                          type="file"
                          id="uSBLNForm"
                          name="uSBLNForm"
                          accept=".pdf"
                          onChange={updateFileFormInput}
                        />
                        {uSBLNForm || form.passed ? (
                          ''
                        ) : (
                          <div className="invalid-feedback">
                            Please submit valid certification
                          </div>
                        )}
                      </div>
                    )
                  )}
                </F40FormGroup>
                <br />
                <F40FormGroup
                  style={{ display: 'inline-flex', alignItems: 'baseline' }}
                  width="100%"
                >
                  <input
                    onChange={(e) => handleCheckbox(e)}
                    type="checkbox"
                    value="HUB Zone Business Enterprise (HZBE)"
                    disabled={form.attachments.some((x) => x.type == 'HZBE')}
                    checked={form.federalCertification.includes(
                      'HUB Zone Business Enterprise (HZBE)'
                    )}
                  />
                  <label className="cert-label">
                    HUB Zone Business Enterprise (HZBE)
                  </label>
                  {form.attachments.some((x) => x.type == 'HZBE') ? (
                    <div style={{ width: '100%' }}>
                      <a
                        style={{ marginRight: '8px' }}
                        href={
                          form.attachments.find((x) => x.type == 'HZBE')
                            ?.filePath
                        }
                        target="_blank"
                      >
                        {
                          form.attachments.find((x) => x.type == 'HZBE')
                            ?.fileName
                        }
                      </a>
                      <i
                        className="bi bi-trash"
                        onClick={() =>
                          setRemoveAttachmentId(
                            form.attachments.find((x) => x.type == 'HZBE')
                              ?.id ?? null
                          )
                        }
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        title="Remove attachment"
                      ></i>
                    </div>
                  ) : (
                    form.federalCertification.includes(
                      'HUB Zone Business Enterprise (HZBE)'
                    ) && (
                      <div>
                        <input
                          className={
                            hZBEForm || form.passed ? '' : 'is-invalid'
                          }
                          type="file"
                          id="hZBEForm"
                          name="hZBEForm"
                          accept=".pdf"
                          onChange={updateFileFormInput}
                        />
                        {hZBEForm || form.passed ? (
                          ''
                        ) : (
                          <div className="invalid-feedback">
                            Please submit valid certification
                          </div>
                        )}
                      </div>
                    )
                  )}
                </F40FormGroup>
                <br />
                <F40FormGroup
                  style={{ display: 'inline-flex', alignItems: 'baseline' }}
                  width="100%"
                >
                  <input
                    onChange={(e) => handleCheckbox(e)}
                    type="checkbox"
                    value="Small Disadvantaged Busniess Enterprise (DBE)"
                    disabled={form.attachments.some((x) => x.type == 'DBE')}
                    checked={form.federalCertification.includes(
                      'Small Disadvantaged Busniess Enterprise (DBE)'
                    )}
                  />
                  <label className="cert-label">
                    Small Disadvantaged Busniess Enterprise (DBE)
                  </label>
                  {form.attachments.some((x) => x.type == 'DBE') ? (
                    <div style={{ width: '100%' }}>
                      <a
                        style={{ marginRight: '8px' }}
                        href={
                          form.attachments.find((x) => x.type == 'DBE')
                            ?.filePath
                        }
                        target="_blank"
                      >
                        {
                          form.attachments.find((x) => x.type == 'DBE')
                            ?.fileName
                        }
                      </a>
                      <i
                        className="bi bi-trash"
                        onClick={() =>
                          setRemoveAttachmentId(
                            form.attachments.find((x) => x.type == 'DBE')?.id ??
                            null
                          )
                        }
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        title="Remove attachment"
                      ></i>
                    </div>
                  ) : (
                    form.federalCertification.includes(
                      'Small Disadvantaged Busniess Enterprise (DBE)'
                    ) && (
                      <div>
                        <input
                          className={dBEForm || form.passed ? '' : 'is-invalid'}
                          type="file"
                          id="dBEForm"
                          name="dBEForm"
                          accept=".pdf"
                          onChange={updateFileFormInput}
                        />
                        {dBEForm || form.passed ? (
                          ''
                        ) : (
                          <div className="invalid-feedback">
                            Please submit valid certification
                          </div>
                        )}
                      </div>
                    )
                  )}
                </F40FormGroup>
                <br />
                <F40FormGroup
                  style={{ display: 'inline-flex', alignItems: 'baseline' }}
                  width="100%"
                >
                  <input
                    onChange={(e) => handleCheckbox(e)}
                    type="checkbox"
                    value="Service-Disabled Veteran Owned Business Enterprise (DVBE)"
                    disabled={form.attachments.some((x) => x.type == 'DVBE')}
                    checked={form.federalCertification.includes(
                      'Service-Disabled Veteran Owned Business Enterprise (DVBE)'
                    )}
                  />
                  <label className="cert-label">
                    Service-Disabled Veteran Owned Business Enterprise (DVBE)
                  </label>
                  {form.attachments.some((x) => x.type == 'DVBE') ? (
                    <div style={{ width: '100%' }}>
                      <a
                        style={{ marginRight: '8px' }}
                        href={
                          form.attachments.find((x) => x.type == 'DVBE')
                            ?.filePath
                        }
                        target="_blank"
                      >
                        {
                          form.attachments.find((x) => x.type == 'DVBE')
                            ?.fileName
                        }
                      </a>
                      <i
                        className="bi bi-trash"
                        onClick={() =>
                          setRemoveAttachmentId(
                            form.attachments.find((x) => x.type == 'DVBE')
                              ?.id ?? null
                          )
                        }
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        title="Remove attachment"
                      ></i>
                    </div>
                  ) : (
                    form.federalCertification.includes(
                      'Service-Disabled Veteran Owned Business Enterprise (DVBE)'
                    ) && (
                      <div>
                        <input
                          className={
                            dVBEForm || form.passed ? '' : 'is-invalid'
                          }
                          type="file"
                          id="dVBEForm"
                          name="dVBEForm"
                          accept=".pdf"
                          onChange={updateFileFormInput}
                        />
                        {dVBEForm || form.passed ? (
                          ''
                        ) : (
                          <div className="invalid-feedback">
                            Please submit valid certification
                          </div>
                        )}
                      </div>
                    )
                  )}
                </F40FormGroup>
                <br />
                <F40FormGroup
                  style={{ display: 'inline-flex', alignItems: 'baseline' }}
                  width="100%"
                >
                  <input
                    onChange={(e) => handleCheckbox(e)}
                    type="checkbox"
                    value="Lesbian, Gay, Bisexual and/or Transgender Owned Business Enterprise (LGBTBE)"
                    disabled={form.attachments.some((x) => x.type == 'LGBTBE')}
                    checked={form.federalCertification.includes(
                      'Lesbian, Gay, Bisexual and/or Transgender Owned Business Enterprise (LGBTBE)'
                    )}
                  />
                  <label className="cert-label">
                    Lesbian, Gay, Bisexual and/or Transgender Owned Business
                    Enterprise (LGBTBE)
                  </label>
                  {form.attachments.some((x) => x.type == 'LGBTBE') ? (
                    <div style={{ width: '100%' }}>
                      <a
                        style={{ marginRight: '8px' }}
                        href={
                          form.attachments.find((x) => x.type == 'LGBTBE')
                            ?.filePath
                        }
                        target="_blank"
                      >
                        {
                          form.attachments.find((x) => x.type == 'LGBTBE')
                            ?.fileName
                        }
                      </a>
                      <i
                        className="bi bi-trash"
                        onClick={() =>
                          setRemoveAttachmentId(
                            form.attachments.find((x) => x.type == 'LGBTBE')
                              ?.id ?? null
                          )
                        }
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        title="Remove attachment"
                      ></i>
                    </div>
                  ) : (
                    form.federalCertification.includes(
                      'Lesbian, Gay, Bisexual and/or Transgender Owned Business Enterprise (LGBTBE)'
                    ) && (
                      <div>
                        <input
                          className={
                            lGBTBEForm || form.passed ? '' : 'is-invalid'
                          }
                          type="file"
                          id="lGBTBEForm"
                          name="lGBTBEForm"
                          accept=".pdf"
                          onChange={updateFileFormInput}
                        />
                        {lGBTBEForm || form.passed ? (
                          ''
                        ) : (
                          <div className="invalid-feedback">
                            Please submit valid certification
                          </div>
                        )}
                      </div>
                    )
                  )}
                </F40FormGroup>
                <br />
              </div>
            </div>
          </F40FormRow>
        </div>
        <div
          style={{
            marginTop: '8px',
            marginBottom: '8px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <a
            target="_blank"
            href="/pdf/CSCS Safety Heath Manual 2024. (2.24).pdf"
          >
            CSCS Safety Health Manual
          </a>

          {form?.vendorType === "Subcontractor" || form?.vendorType === "Both" ? (
            <div>

              <br />
              <input onChange={updateFormInput} defaultChecked={form.isSubAcknowledged} type="checkbox" id="acknowledgement" name="isSubAcknowledged" />
              <label htmlFor="acknowledgement">
                By submitting this form, I agree to the acknowledgements outlined in the <a target="_blank" href="/pdf/Partner Acknowledgement Form.pdf">
                  Partner Acknowledgement Form
                </a>
              </label>
              <br />
            </div>
          ) : null}
        </div>
        <div className="panel panel-default">
          <div
            className=""
            style={{
              display: 'flex',
              padding: '20px 0px',
            }}
          >
            <div className="col-sm-2" style={{ width: '100px' }}>
              <button
                className="btn btn-dark-blue submitButton"
                onClick={saveSubmitButton}
                disabled={!form.isSubAcknowledged && form.vendorType === "Subcontractor"}
              >
                {subForm ? 'Save' : 'Submit'}
              </button>
            </div>

            <div
              style={{
                position: 'relative',
                minHeight: '1px',
                paddingRight: '15px',
                paddingLeft: '15px',
              }}
              className="col-sm-8"
            >
              Thank you for your interest in becoming a partner with Core States
              Construction Services. Someone on our staff will reach out to you
              shortly to reivew your application. If accepted, additional
              information may be required. Questions can be sent to
              info@core-states.com.
            </div>
          </div>
          {/*<div className="col-sm-4 manual">*/}
          {/*  {form.vendorType != "Material Supplier Only" ? PdfDownloaderButton("ConstructionEmployeeSafetyManual") : ""}*/}
          {/*</div>*/}
        </div>

        {subFormStore.message ? modalWarning() : ''}
        {subFormStore.success ? modal_Submitted() : ''}
      </div>
    </div>
  );
};
