import * as React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { P2Page } from './components/p2Forms/P2Page';

export const App = () => {
  return (
    // @ts-ignore
    <Layout>
      {/* @ts-ignore */}
      <Route exact path="/" component={Home} />
      {/* @ts-ignore */}
      <Route exact path="/p2/:token" component={P2Page} />
    </Layout>
  );
};
